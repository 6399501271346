.panel-omg {
    overflow: hidden;

    .tags {
        color: var(--accent);
        margin-bottom: 8px;
    }

    .title {
        font-size: 22px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 8px;
        color: var(--text_primary);
    }

    .desc {
        color: var(--text_subhead);
        margin-bottom: 8px;
    }

    .btn-1 {
        margin-top: 24px;
    }

    .btn-2 {
        margin-top: 16px;
        display: flex;
        flex-direction: row;
    }
}

.also {
    color: var(--text_primary);
}