.omg-loader {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    z-index: 1;
    font-weight: 500;

	& .icon {
		margin-right: 5px;
	}

	.text {
		color: #000000;
	}
}
