#canvas-bg-father {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.loaded {

    #canvas-bg-father {
        opacity: 0;
        transform: scale(1.1);
    }
}