.questions-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
	padding-bottom: 50px;

    .question {
        width: calc(50% - 10px);
        padding: 30% 7.5px 7.5px 7.5px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin: 5px;
        font-weight: bold;
        display: flex;
        align-items: flex-end;
        color: #fff;
        font-size: 18px;
        line-height: 20px;
        text-shadow: 0 0 0 rgba(0,0,0,0);
        position: relative;

        &:nth-child(8n), &:nth-child(8n+1) {
            width: 100%;

            .bg {
                background-position: center top;
            }
        }

        .bg {
            background-size: cover;
            background-position: center center;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
            border-radius: 10px;

            &:after {
                content: '';
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                top: 0;
                box-shadow: inset 0 -100px 100px -50px rgba(0,0,0,0.75);
                border-radius: 10px;
                background: #0b2a4614;
            }
        }

        .title {
            z-index: 1;
        }

        .flag {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.8);
            color: #000;
            font-weight: 400;
            font-size: 14px;
            border-radius: 5px;
            padding: 2px 6px;
            margin-left: 5px;
            margin-top: 5px;
        }
    }

    .question:after {
        padding-bottom: 100%;
    }
}
