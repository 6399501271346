.PromoBanner {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    padding-bottom: 0;
    padding-bottom: calc(var(--safe-area-inset-bottom) / 2);

    &__head {
        padding: 0;
        height: 0;
    }

    &__age {
        position: absolute;
        right: 100px;
        top: 9px;
        transform: scale(0.75);
        background: var(--background_content);
    }

    &__label {
        display: none;
    }

    &__close {
        width: 38px;
		margin-top: 7px;
    }

    .Avatar {
        padding: 0;

        img {
            width: 50px !important;
            height: 50px !important;
        }
    }

    .SimpleCell__main {
        margin-left: 8px;
        padding: 0;
    }

    .SimpleCell__after {
        margin-right: 30px;
    }

    .SimpleCell__children {
        font-size: 14px;
        line-height: 14px;
    }

    .SimpleCell__description {
        margin-top: 2px;
        font-size: 11px;
        line-height: 11px;
    }

    .Tappable {
        padding-right: 8px;
        padding-left: 8px;
    }
}
