#canvas-father {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    pointer-events: none;
    z-index: 5;
}