.omg-picture {
    width: 100%;
    background-color: var(--background_content);
    margin-top: 24px;
    min-height: calc(3 / 4 * 100vw - 24px);
    position: relative;

    img {
        vertical-align: top;
        width: 100%;
        opacity: 0;
        transition: all 0.25s ease-in-out;
    }

    &.loaded {

        img {
            opacity: 1;
            transition: all 0.5s ease-in-out;
        }
    }
}